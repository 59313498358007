import { Component, OnInit } from '@angular/core';
import { CustomerComponentGlobal } from '../../customer-component-global';

@Component({
  selector: 'app-home-component',
  templateUrl: './home-component.component.html',
  styleUrls: ['./home-component.component.scss'],
})
export class HomeComponentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
