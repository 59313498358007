import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { GLOBAL } from '../global';
import { RestApiService } from './helper.httpServices';
import { LocalStorageService } from './local-storage.service';
import { LoginSignupService } from '../node-api/login-signup.service';

@Injectable()
export class CommonService {
  constructor(
    private http: RestApiService,
    private localStorage: LocalStorageService,
    private loginSignupService : LoginSignupService
  ) // private deviceService: DeviceDetectorService
  {}

  private productDetail = new BehaviorSubject(null);
  product = this.productDetail.asObservable();

  putProductData(product: any) {
    this.productDetail.next(product);
  }

  notificationCountSubject = new Subject<any>();

  searchTextHeader = new Subject<string>();
  dataString$ = this.searchTextHeader.asObservable();
  search(event) {
    if (event.target.value.length >= 3) {
      this.searchTextHeader.next(event.target.value);
    } else {
      this.searchTextHeader.next('');
    }
  }

  notificationCountUpdate() {
    this.notificationCountSubject.next();
  }

  compareCompanies = new Subject<string[]>();
  compareCompanyList = this.compareCompanies.asObservable();

  searchOptionList(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'company/customer/search/option', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  addToFollowList(id) {
    let isInFollowList = false;
    let followList = JSON.parse(
      this.localStorage.getItem('followCompanyList')
        ? this.localStorage.getItem('followCompanyList')
        : '[]'
    );
    if (followList.includes(id)) {
      followList.splice(followList.indexOf(id), 1);
      isInFollowList = false;
    } else if (followList.length >= 0) {
      followList.push(id);
      isInFollowList = true;
    }
    //SHOW error
    this.localStorage.setItem('followCompanyList', JSON.stringify(followList));
    return isInFollowList;
  }
  addToCompareList(CIN) {
    let isInCompareList = false;
    let companyList = JSON.parse(
      this.localStorage.getItem('compareCompanyList')
        ? this.localStorage.getItem('compareCompanyList')
        : '[]'
    );
    if (companyList.includes(CIN)) {
      companyList.splice(companyList.indexOf(CIN), 1);
      isInCompareList = false;
    } else if (companyList.length < 3) {
      companyList.push(CIN);
      isInCompareList = true;
    }
    //SHOW error
    this.localStorage.setItem(
      'compareCompanyList',
      JSON.stringify(companyList)
    );
    this.compareCompanies.next(companyList);
    return isInCompareList;
  }

  isInFollowlist(id) {
    // let userData : any;
    let isInFollowlist = false;
    // let user_id = this.localStorage.getItem('userId');
    // this.loginSignupService.getUserData(user_id).then(
    //   (data: any) => {
    //     userData = data;
    //     this.localStorage.setItem('followCompanyList', JSON.stringify(userData.company_follow_list));
    //   },
    //   (err) => {
    //   }
    // );
    let companyList = JSON.parse(
      this.localStorage.getItem('followCompanyList')
        ? this.localStorage.getItem('followCompanyList')
        : '[]'
    );
    if (companyList.includes(id)) {
      return (isInFollowlist = true);
    } else {
      return (isInFollowlist = false);
    }
  }

  isInComparelist(CIN) {
    let isInCompareList = false;
    let companyList = JSON.parse(
      this.localStorage.getItem('compareCompanyList')
        ? this.localStorage.getItem('compareCompanyList')
        : '[]'
    );
    if (companyList.includes(CIN)) {
      return (isInCompareList = true);
    } else {
      return (isInCompareList = false);
    }
  }
  getCompareCompanyList() {
    return JSON.parse(
      this.localStorage.getItem('compareCompanyList')
        ? this.localStorage.getItem('compareCompanyList')
        : '[]'
    );
  }

  getCompareCompanyData(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'company/customer/compare/data', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  addFollowInCompanyList(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'company/customer/follow-unfollow-company',
          requestData
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getNotifications(requestData: any) {
    return new Promise((resolve, reject) => {
      if (this.localStorage.getItem('onSession')) {
        this.http
          .post<any>(GLOBAL.url + 'notifications/findAll', requestData)
          .subscribe(
            (data) => {
              resolve(data);
            },
            (err) => {
              reject(err);
            }
          );
      } else {
        reject({ error: 'not login' });
      }
    });
  }

  markAllNotificationRead() {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'notifications/mark-all-read', null)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  markNotificationReadById(requestObject: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'notifications/mark-read-by-id', requestObject)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getNotificationCount() {
    return new Promise((resolve, reject) => {
      if (this.localStorage.getItem('onSession')) {
        this.http.get<any>(GLOBAL.url + 'notifications/unread-count').subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        reject({ error: 'not login' });
      }
    });
  }
}
