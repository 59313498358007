<app-search></app-search>
<app-interested></app-interested>
<app-kredible-pro></app-kredible-pro>
<!-- <app-compare></app-compare> -->
<section class="section-compaineslist">
  <div class="container">
    <app-horizontal-company-list
      [title]="'FinTech Companies'"
      [industry]="'FINTECH'"
    ></app-horizontal-company-list>
    <app-horizontal-company-list
      [title]="'EdTech Companies'"
      [industry]="'EDTECH'"
    ></app-horizontal-company-list>
  </div>
</section>
<app-news></app-news>
<!-- <app-discover></app-discover> -->
