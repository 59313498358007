import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';
import { NewsService } from 'src/app/shared/services/wordpress-api/news.service';

@Component({
  selector: 'app-google-news-layout',
  templateUrl: './google-news-layout.component.html',
  styleUrls: ['./google-news-layout.component.scss'],
})
export class GoogleNewsLayoutComponent implements OnInit {
  @Input() title: any;
  @Input() showTittle: boolean;
  @Input() categoryName: any = '';
  @Input() showNewsImage: boolean;
  @Input() home: boolean;
  @Input() showExpandMore: boolean = false;
  @Input() cin: any;
  @Input() count: Number = 10;

  @Output() hideLayout: EventEmitter<any> = new EventEmitter();

  private request: any = {
    field: 'date',
    order: -1,
    page: 0,
    rows: 10,
    filter: {
      category: '',
    },
  };
  newsData: any = [];
  category: Array<any> = [];
  data: any = {};
  totalRecords = 0;

  constructor(
    public companyService: CompanyService,
    private newsService: NewsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.home === true) {
      this.getRecentNewsDetails();
    } else {
      this.getNewsDetails();
    }
  }

  getRecentNewsDetails() {
    this.request.rows = 100;
    this.request.page = 0;
    this.newsService
      .getrecentNewsList(this.request)
      .then((data: any) => {
        this.newsData = [...this.newsData, ...data.data];
        this.totalRecords = data.totalRecords;
        if( this.newsData.length == 0){
          this.hideLayout.emit(false);
        }
      })
      .catch((err: any) => {
        console.log(err)
        if( this.newsData.length == 0){
          this.hideLayout.emit(false);
        }
      });
  }

  getNewsDetails() {
    this.request.cin = this.cin;
    this.request.filter.category = this.category;
    this.request.rows = this.count;

    this.data = {
      matchMode: 'startsWith',
      operator: 'and',
      value: this.categoryName,
    };
    this.category.push(this.data);

    this.newsService
      .getNewsList(this.request)
      .then((data: any) => {
        this.newsData = [...this.newsData, ...data.data];
        this.totalRecords = data.totalRecords;
        if( this.newsData.length == 0){
          this.hideLayout.emit(false);
        }
      })
      .catch((err: any) => {
        console.log(err)
        if( this.newsData.length == 0){
          this.hideLayout.emit(false);
        }
      });
  }

  showMore() {
    this.request = {
      cin: this.cin,
      field: 'sno',
      order: 1,
      page: this.request.page + 1,
      rows: this.count,
      filter: {
        category: this.category,
      },
    };
    this.getNewsDetails();
  }

  selectTab() {
    this.router.navigate(['company', this.newsData[0]?.company_name], {
      queryParams: { tab: 'news' },
    });
  }
}
