import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {
  APP_PATHS,
  COMMON_CONSTANTS,
  LOGIN_PATHS,
} from '../constants/common-constants';
import { LoginSignupService } from '../services/node-api/login-signup.service';

@Injectable()
export class USAuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private loginSignupService: LoginSignupService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let session = localStorage.getItem('onSession');
    if (session == 'true') {
      return true;
    } else {
      this.router.navigate([APP_PATHS.USER + '/' + LOGIN_PATHS.LOGIN]);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  }
}
