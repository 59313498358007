import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { APP_PATHS } from './shared/constants/common-constants';
import { HomePageComponent } from './modules/customer-module/pages/home-page/home-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: APP_PATHS.HOME, loadChildren: () => import('./modules/customer-module/customer.module').then(m => m.CustomerModule) },
  { path: APP_PATHS.USER, loadChildren: () => import('./modules/login-signup-module/login-signup.module').then(m => m.LoginSignUpModule) },
  { path: APP_PATHS.ERROR_404, component: NotFoundComponent },
  { path: APP_PATHS.UNIVERSAL_ROUTE, redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
