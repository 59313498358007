import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { GLOBAL_VARIABIALES } from '../../constants/static-variable';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.localStorage.getItem('token');

    if (token !== null && token !== undefined && token !== 'undefined') {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    } else {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          GLOBAL_VARIABIALES.ROLE.GUEST
        ),
      });
    }

    if (request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    } else {
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      })
    );
  }
}
