import { Injectable } from '@angular/core';
import { RestApiService } from '../angular-service/helper.httpServices';
import { LocalStorageService } from '../angular-service/local-storage.service';
import { GLOBAL } from '../global';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private http: RestApiService,
    // private localStorage: LocalStorageService
  ) {}

  //get all companies
  getCompanies(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'company/customer/findAll', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  suggestionData(requestData: any, token) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'suggestion/customer/create',
          requestData,
          token
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAllCompanies(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'company/customer/list/page', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCompanyDetail(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'company/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // getCompanyByCIN(requestData: any) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get<any>(GLOBAL.url + "company/find" + requestData)
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  getFundingDetail(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'funding/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCompanyHistoricalDetails(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'company/customer/historical-details',
          requestData
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFinancialsDetail(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'financials/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getROCList(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http.post<any>(GLOBAL.url + 'roc/findAll', requestData).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getUserNewCompanyRequestList(){
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'newcompany/customer/user/myrequest/list')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  newCompanyData(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'newcompany/customer/create', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // newCompanyCreate() {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get<any>(GLOBAL.url + 'newcompany/customer/create/count')
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  // generateSKU(requestData: any) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post<any>(GLOBAL.url + 'product/customer/generate/sku', requestData)
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  getIndustryReport() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'product/customer/industry/list')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  customerReportData(requestData: any, reCaptchaToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.url + 'customer-report/customer/create',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
