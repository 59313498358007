import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';

@Component({
  selector: 'app-compact-company-list',
  templateUrl: './compact-company-list.component.html',
  styleUrls: ['./compact-company-list.component.scss'],
})
export class CompactCompanyListComponent implements OnInit {
  companyList: any = [];
  paginationRequest = {
    field: 'cin',
    order: 1,
    rows: 14,
    page: 0,
    filter: {},
    totalRecords: 0,
  };

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies() {
    let query = {
      field: this.paginationRequest.field,
      order: this.paginationRequest.order,
      rows: this.paginationRequest.rows,
      page: this.paginationRequest.page,
      filter: this.paginationRequest.filter,
    };
    this.companyService
      .getAllCompanies(query)
      .then((data) => {
        this.companyList = [...this.companyList, ...data['data']];
      })
      .catch((err: any) => {
        // console.log(err)
      });
  }
}
