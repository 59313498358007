import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/angular-service/local-storage.service';

@Component({
  selector: 'app-kredible-pro',
  templateUrl: './kredible-pro.component.html',
  styleUrls: ['./kredible-pro.component.scss'],
})
export class KredibleProComponent implements OnInit {

  isPremium = false;
  plan: any;
  isAdvance = false;
  isFree = false;

  constructor(private localStorageService: LocalStorageService,) {}

  ngOnInit(): void {
    this.plan = this.localStorageService.getItem('plan');
    if (this.plan === 'plan_advance') {
      this.isAdvance = true;
    } else if (this.plan === 'plan_premium') {
      this.isPremium = true;
    } else {
      this.isFree = true;
    }
  }
}
