export const GLOBAL_VARIABIALES = {
  ROLE: {
    SUPER_ADMIN: 'su_admin',
    ADMIN: 'admin',
    SUBSCRIBER: 'subscriber',
    GUEST: 'guest',
  },
  TYPE_OF_COMPANY: [
    { key: 'PUBLIC_LIMITED_COMPANY', label: 'Public Limited Company' },
    { key: 'PRIVATE_LIMITED_COMPANY', label: 'Private Limited Company' },
    { key: 'FOREIGN_COMPANY', label: 'Foreign Company' },
    { key: 'OTHERS', label: 'Others' },
  ],
  COMPANY_STATUS: [
    { code: 'ACTIVE', label: 'Active' },
    { code: 'ACQUIRED', label: 'Acquired' },
    { code: 'SHUT_DOWN', label: 'Shut Down' },
    { code: 'LISTED', label: 'Listed' },
    { code: 'UNFUNDED', label: 'Unfunded' },
    { code: 'AMALGAMATED', label: 'Amalgamated' },
    { code: 'MERGED', label: 'Merged' },
    { code: 'DRAFT', label: 'Draft' },
  ],
};

export const colorScheme = {
  domain: [
    '#0C87F9',
    '#096CC7',
    '#075195',
    '#043663',
    '#021B31',
    '#000000',
    '#2493F9',
    '#54ABFA',
    '#85C3FB',
    '#B6DBFD',
    '#E6F3FE',
  ],
};
