import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivationEnd, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { filter, map } from 'rxjs/operators';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';
import { ToastService } from 'src/app/shared/services/angular-service/toast.service';

@Component({
  selector: 'app-suggestion-form',
  templateUrl: './suggestion-form.component.html',
  styleUrls: ['./suggestion-form.component.scss'],
})
export class SuggestionFormComponent implements OnInit {
  @Output() display: EventEmitter<any> = new EventEmitter();
  // @Input() display: any;
  suggestionForm: FormGroup;
  company_name: any;
  cin: any;
  company: any;
  tab: any;
  token: any = undefined;
  company_url:any;

  constructor(
    public router: Router,
    public companyService: CompanyService,
    public toastService: ToastService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.suggestionForm = new FormGroup({
      cin: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.maxLength(21),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      suggestion: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      tc: new FormControl('', Validators.required),
    });
    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof ActivationEnd &&
            Object.keys(e.snapshot.params).length > 0
        ),
        map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
      )
      .subscribe((data) => {
        // console.log(data)
        this.tab = data.tab;
        if (data.tab) {
          this.company_name = data.name;
          this.company_url = this.company_name.toLowerCase()
          this.company_url = this.company_url.replaceAll(' ', '-');
          let request = '?companyUrl=' + this.company_url;
          this.companyService
            .getCompanyDetail(request)
            .then((data: any) => {
              this.company = data;
              this.cin = data.cin;
              this.suggestionForm.patchValue({ cin: this.cin });
            })
            .catch((err: any) => {});
        }
      });
  }

  ngOnInit(): void {
    this.recaptchaV3Service
      .execute('suggestionRequest')
      .subscribe((token: string) => {
        this.token = token;
        // console.debug(`Token [${token}] generated`);
      });
  }

  suggestionRequest() {
    let requestData = {
      cin: this.cin,
      name: this.suggestionForm.value.name,
      email: this.suggestionForm.value.email,
      company_name: this.company_name,
      suggestion: this.suggestionForm.value.suggestion,
    };
    this.companyService.suggestionData(requestData, this.token).then(
      (data: any) => {
        this.toastService.addSingle(
          'success',
          'You have successfully send your suggestion!',
          ''
        );
        this.suggestionForm.reset();
        this.display.emit(false);
        this.suggestionForm.patchValue({ cin: this.cin });
        window.location.reload();
        // this.router.navigateByUrl('/company/' + this.company_name + '/' + this.tab);
      },
      (err) => {
        // console.log(err);
        // this.toastService.addSingle("error", "Error", err.error.message);
      }
    );
  }
}
