import { Injectable } from '@angular/core';
import { RestApiService } from '../angular-service/helper.httpServices';
import { LocalStorageService } from '../angular-service/local-storage.service';
import { GLOBAL } from '../global';

@Injectable({
  providedIn: 'root',
})
export class SettingService {

  constructor(
    private restApi: RestApiService,
  ) { }

  getLocationDropdown() {
    return JSON.parse(localStorage.getItem('locations'));
  }

  getIndustryDropdown() {
    return JSON.parse(localStorage.getItem('industries'));
  }

  getFinancialYearDropdown() {
    return JSON.parse(localStorage.getItem('financialYears'));
  }


  getAllDropdowns() {
    return new Promise((resolve, reject) => {
      this.restApi
        .get<any>(GLOBAL.url + 'settings/user/dropdowns')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
