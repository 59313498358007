import { Injectable } from '@angular/core';
import { RestApiService } from '../angular-service/helper.httpServices';
import { LocalStorageService } from '../angular-service/local-storage.service';
import { GLOBAL } from '../global';
import { environment } from 'src/environments/environment';
import { CommonService } from '../angular-service/common.services';
import { ToastService } from '../angular-service/toast.service';

@Injectable({
  providedIn: 'root',
})
export class TansactionService {
  constructor(
    private http: RestApiService,
    private localStorage: LocalStorageService,
    private commonService: CommonService,
    private toastService: ToastService
  ) {}

  verifyCart(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.paymentUrl + 'customer/verify', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getTransactionData(id) {
    return new Promise((resolve, reject) => {
      this.http
        .get(GLOBAL.paymentUrl + 'customer/transaction/find/' + id)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createCartPayOut(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.cartUrl + 'customer/cart/create', requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  addProductToCart(requestData: any) {
    if(this.localStorage.getItem("completeProfile")){
      return new Promise((resolve, reject) => {
        this.http
          .post<any>(GLOBAL.cartUrl + 'customer/cart/add', requestData)
          .subscribe(
            (data) => {
              this.commonService.notificationCountUpdate();
              resolve(data);
            },
            (err) => {
              reject(err);
            }
          );
      });
    }else{
      this.toastService.addSingle('error', 'Error', "Please complete your profile and try again.");
    }
  }

  currentPrice() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.paymentUrl + 'customer/transaction/price/find')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  createCartRazorpay(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.cartUrl + 'customer/cart/razor/pay',
          requestData
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  // updateCartPayOut(requestData: any) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post<any>(GLOBAL.url + "subscriber/customer/cart/update", requestData)
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  getCartDetails() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.cartUrl + 'customer/cart/find')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getUserTransactionList() {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.paymentUrl + 'customer/transaction/find/')
        .subscribe(
          (data: any) => {
            // this.localStorage.setItem('transactionList', JSON.stringify(data.data));
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  removeSkuFromCart(cartId, sku) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          GLOBAL.cartUrl +
            `customer/cart/remove/item?cart_id=${cartId}&sku=${sku}`
        )
        .subscribe(
          (data: any) => {
            // this.localStorage.setItem('transactionList', JSON.stringify(data.data));
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
