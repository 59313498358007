import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { CommonService } from 'src/app/shared/services/angular-service/common.services';
import { LoginSignupService } from 'src/app/shared/services/node-api/login-signup.service';
import { LocalStorageService } from 'src/app/shared/services/angular-service/local-storage.service';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent implements OnInit {
  @Input() id: any;
  @Input() company: any;
  isAddedInCompareList: any = false;
  loginEmail: any;
  isAddedInFollowList: boolean;
  // companyService: any;
  isPremium = false;
  plan: any;
  isAdvance = false;
  isFree = false;
  showPopUp = false;
  userId;

  constructor(
    private commonService: CommonService,
    private loginSignupService: LoginSignupService,
    private localStorageService: LocalStorageService,
    private router: Router,
    public confirmationService: ConfirmationService,
    private companyService: CompanyService
  ) {
    this.userId = localStorageService.getItem('userId');
  }

  ngOnInit(): void {
    if (this.id != undefined && this.company == undefined) {
      this.companyService
        .getCompanyDetail('?id=' + this.id)
        .then((data) => {
          this.company = data;
          this.commonService.compareCompanyList.subscribe((data) => {
            this.isAddedInCompareList = this.commonService.isInComparelist(
              this.company.cin
            );
          });
          this.isAddedInCompareList = this.commonService.isInComparelist(
            this.company.cin
          );
          this.isAddedInFollowList = this.commonService.isInFollowlist(
            this.company._id
          );
          this.plan = this.localStorageService.getItem('plan');
          if (this.plan === 'plan_advance') {
            this.isAdvance = true;
          } else if (this.plan === 'plan_premium') {
            this.isPremium = true;
          } else {
            this.isFree = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (this.company) {
      this.commonService.compareCompanyList.subscribe((data) => {
        this.isAddedInCompareList = this.commonService.isInComparelist(
          this.company.cin
        );
      });
      this.isAddedInCompareList = this.commonService.isInComparelist(
        this.company.cin
      );
      this.isAddedInFollowList = this.commonService.isInFollowlist(
        this.company._id
      );
      this.plan = this.localStorageService.getItem('plan');
      if (this.plan === 'plan_advance') {
        this.isAdvance = true;
      } else if (this.plan === 'plan_premium') {
        this.isPremium = true;
      } else {
        this.isFree = true;
      }
    }
  }

  // compareCompany() {
  //   this.isAddedInCompareList = this.commonService.addToCompareList(this.company.cin);
  // }

  confirm(event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'This feature is only available for Advance and Premium users.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['/prices']);
      },
      reject: () => {
        return false;
      },
    });
  }

  followCompany() {
    this.loginEmail = this.loginSignupService.getUserSession()?.user?.name;
    if (
      this.loginEmail !== undefined &&
      this.loginEmail !== '' &&
      this.loginEmail !== null
    ) {
      // this.isAddedInFollowList =this.commonService.addToFollowList(this.company._id);
      let request = {
        cin: this.company.cin,
      };
      this.commonService
        .addFollowInCompanyList(request)
        .then((data: any) => {
          this.localStorageService.setItem('followCompanyList', JSON.stringify(data.company_follow_list));
          window.location.reload();
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      this.router.navigate(['/user/login']);
    }
  }
}
