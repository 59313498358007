import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/angular-service/common.services';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('searchOption') searchOption: ElementRef;
  companyData: any;
  constructor(private router: Router, private commonService: CommonService) {}

  ngOnInit(): void {}

  searchQuery = '';

  search(event) {
    if (event.keyCode == 13 || event.keyCode == 9) {
      this.router.navigate(['/search'], {
        queryParams: { s: event.target.value },
      });
      setTimeout(() => {
        this.commonService.search(event);
      }, 1000);
    } else if (this.searchOption.nativeElement.value.length > 0) {
      fromEvent(this.searchOption.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(150),
          distinctUntilChanged(),
          tap((event: KeyboardEvent) => {
            if (this.searchOption.nativeElement.value != this.searchQuery) {
              let requestData = {
                textSearch: this.searchOption.nativeElement.value,
              };
              this.commonService
                .searchOptionList(requestData)
                .then((data: any) => {
                  if (data.data.length > 0) {
                    this.companyData = data.data;
                  } else {
                    this.companyData = undefined;
                  }
                  // console.log(this.companyData)
                })
                .catch((err: any) => {
                  // console.log(err)
                  this.companyData = undefined;
                });
            }
          })
        )
        .subscribe();
    } else {
      this.companyData = undefined;
    }
  }

  searchDataValue(name) {
    this.companyData = [];
    this.searchOption.nativeElement.value = name;
    this.router.navigate(['/search'], { queryParams: { s: name } });
  }
}
