import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-slider',
  templateUrl: './quote-slider.component.html',
  styleUrls: ['./quote-slider.component.scss'],
})
export class QuoteSliderComponent implements OnInit {
  quotesList = [
    'India is second in the world in terms of number of startups, leading by United States. Third in the list is United Kingdom.',
    'The total funding that Indian startups received stood close to $38 Billion in 2021.',
    'Snapdeal started as a daily deals platform in 2010, and later on expanded its operations in online marketplace in 2011.',
    'India is a home of 5% of Unicorns in the world.',
    'The top 3 bootstrap startups in India are Zerodha, Zoho and Wingify.',
  ];

  constructor() {}

  ngOnInit(): void {}
}
