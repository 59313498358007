import { Component, Input, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';

@Component({
  selector: 'app-horizontal-company-list',
  templateUrl: './horizontal-company-list.component.html',
  styleUrls: ['./horizontal-company-list.component.scss'],
})
export class HorizontalCompanyListComponent implements OnInit {
  @Input() title: any = '';
  @Input() category = '';
  @Input() industry: any;

  companyList: any = [];
  paginationRequest = {
    field: 'total_valuation',
    order: -1,
    rows: 10,
    page: 0,
    filter: {},
    totalRecords: 0,
  };

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies() {
    let query = {
      field: this.paginationRequest.field,
      order: this.paginationRequest.order,
      rows: this.paginationRequest.rows,
      page: this.paginationRequest.page,
      filter: {
        industry: [{ value: this.industry, matchMode: 'in', operator: 'or' }],
      },
    };
    this.companyService
      .getCompanies(query)
      .then((data) => {
        this.companyList = data['data'];
      })
      .catch((err: any) => {
        // console.log(err)
      });
  }
}
