<div *ngIf="customerLandingPage">
  <header class="header">
    <div class="container">
      <div
        class="wrap-header d-flex justify-content-between align-items-center"
      >
        <div class="site-logo-wrap">
          <a (click)="homePage()"
            ><img src="assets/images/sitelogo.svg" class="site-logo"
          /></a>
        </div>
        <div class="main-menu d-flex align-items-center">
          <div class="search-input">
            {{ companyDat }}
            <div
              class="input-group rounded"
              [ngClass]="{ 'show-list': companyData !== undefined }"
            >
              <span class="search-icon border-0" id="search-addon">
                <i class="fas fa-search"></i>
              </span>
              <input
                type="search"
                class="form-control rounded"
                placeholder="Search for a company"
                aria-label="Search"
                #searchOption
                (keyup)="search($event)"
                aria-describedby="search-addon"
              />
              <div
                *ngIf="
                  companyData !== undefined &&
                  companyData !== null &&
                  companyData !== ''
                "
                class="search-list-wrap"
                (clickOutside)="companyData = undefined"
              >
                <ul class="search-list">
                  <li
                    *ngFor="let item of companyData"
                    class="search-item"
                    (click)="searchDataValue(item.name)"
                  >
                    <a>{{ item.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <nav class="navbar navbar-default">
            <ul class="nav navbar-nav">
              <!-- <li class="nav-item"><a [routerLink]="['/about-us']" routerLinkActive="active" class="nav-link">About</a></li> -->
              <li class="nav-item">
                <a
                  [routerLink]="['/companies']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Companies</a
                >
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['/reports']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Reports</a
                >
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['/prices']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Pricing</a
                >
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['/blogs']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Blogs</a
                >
              </li>
              <li class="border-line"></li>
              <li *ngIf="user !== null" class="notification-link">
                <a
                  (click)="hideNotificationPopup = !hideNotificationPopup"
                  class="nav-link"
                >
                  <img
                    src="assets/images/icons/notification.svg"
                    class="icon-img"
                  />

                  <span
                    *ngIf="
                      notificationCount != undefined && notificationCount !== 0
                    "
                    class="count"
                    >{{ notificationCount }}</span
                  >
                </a>
                <app-notification-component *ngIf="hide"></app-notification-component>
                <!-- <div class="notification-popup" *ngIf="!hideNotificationPopup">
                  <div class="notify-innerwrap">
                    <h6>Notifications</h6>
                    <app-notification-component [header]="true"></app-notification-component>
                  </div>
                </div> -->
              </li>
              <li *ngIf="user !== null" class="cart-link">
                <a [routerLink]="['/cart']" class="nav-link">
                  <img src="assets/images/icons/cart.svg" class="icon-img" />
                  <span class="count">{{ cartCount }}</span>
                </a>
              </li>
              <li *ngIf="user !== null">
                <a
                  [routerLink]="[APP_PATHS.HOME + '/' + CUSTOMER_PATHS.PROFILE]"
                  class="nav-link"
                  >{{ user.user.name.split(" ")[0] }}</a
                >
              </li>
              <li *ngIf="user == null">
                <a
                  [routerLink]="[APP_PATHS.USER + '/' + LOGIN_PATHS.LOGIN]"
                  class="nav-link"
                  >Login</a
                >
              </li>

              <!-- <li (click)=logout() class="nav-link">Logout</li>  -->
            </ul>
          </nav>
          <div class="hamburger-menu">
            <span class="hamburger-icon show" (click)="showMenu = true"
              ><i class="pi pi-bars"></i
            ></span>
          </div>
        </div>
        <!--- start mobile main menu code -->
        <div class="mobile-menu" [ngClass]="{ 'show-menu': showMenu }">
          <div class="site-logo-wrap d-flex justify-content-between">
            <a [routerLink]="['/']"
              ><img src="assets/images/sitelogo.svg" class="site-logo"
            /></a>
            <span class="close-icon" (click)="showMenu = false"
              ><i class="pi pi-times"></i
            ></span>
          </div>
          <nav class="mobile-navbar">
            <ul class="navmobile">
              <li class="nav-item">
                <a
                  [routerLink]="['/companies']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Companies</a
                >
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['/reports']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Reports</a
                >
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['/prices']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Pricing</a
                >
              </li>
              <li class="nav-item">
                <a
                  [routerLink]="['/blogs']"
                  routerLinkActive="active"
                  class="nav-link"
                  >Blogs</a
                >
              </li>
            </ul>
          </nav>
        </div>
        <!--- End mobile main menu code -->

        <div
          *ngIf="isCompanyDetailsHeader"
          class="menu-bottom d-flex align-items-center"
        >
          <span class="mobile-arrow"><i class="pi pi-angle-right"></i></span>
          <nav class="navbar">
            <ul class="nav navbar-nav">
              <li>
                <a
                  (click)="selectTab('overview')"
                  [ngClass]="{ active: overview }"
                  class="nav-link"
                  >Overview</a
                >
              </li>
              <li>
                <a
                  (click)="selectTab('funding')"
                  [ngClass]="{ active: funding }"
                  class="nav-link"
                  >Funding</a
                >
              </li>
              <li>
                <a
                  (click)="selectTab('financials')"
                  [ngClass]="{ active: financials }"
                  class="nav-link"
                  >Financials</a
                >
              </li>
              <!-- <li><a (click)="selectTab('traction')" [ngClass]="{'active': traction}" class="nav-link">Traction</a></li> -->
              <li>
                <a
                  (click)="selectTab('people')"
                  [ngClass]="{ active: people }"
                  class="nav-link"
                  >People</a
                >
              </li>
              <li>
                <a
                  (click)="selectTab('news')"
                  [ngClass]="{ active: news }"
                  class="nav-link"
                  >News</a
                >
              </li>
              <li>
                <a
                  (click)="selectTab('roc')"
                  [ngClass]="{ active: roc }"
                  class="nav-link"
                  >Company Documents</a
                >
              </li>
              <li class="desktop-hidden" *ngIf="user !== null">
                <a (click)="showDialog()" class="nav-link edit-link"
                  ><img style="width: 14px" src="assets/images/icons/edit.svg"
                /></a>
              </li>
            </ul>
          </nav>
          <div
            class="right-btn-wrap d-flex justify-content-between mobile-hidden"
          >
            <span *ngIf="user !== null" style="position: relative">
              <a (click)="showDialog()" class="nav-link edit-link">
                <img src="assets/images/icons/edit.svg" />
              </a>
            </span>
            <!-- <p-confirmPopup></p-confirmPopup> -->
            <!-- <a href="#" class="download"><img src="assets/images/icons/clouddownload.svg" class="icon-img" /></a> -->
            <!-- <button *ngIf="isPremium || isAdvance" (click)="compareCompany()" [ngClass]="{
                'added-in-compare-list btn-black-bg': isAddedInCompareList
              }" class="btn btn-outline-light btn-grey-bg">
              {{ isAddedInCompareList ? "Added" : "Compare" }}
            </button> -->
            <!-- <button *ngIf="isFree && !isPremium && !isAdvance" (click)="showPopUp = !showPopUp"
              class="btn btn-outline-light btn-grey-bg">
              Compare
            </button> -->
            <!-- <button (click)="compareCompany()" [ngClass]="{'added-in-compare-list btn-black-bg': isAddedInCompareList}"
            class="btn btn-outline-light btn-grey-bg">{{ isAddedInCompareList? "Added":"Compare"}}</button> -->
            <button
              (click)="followCompany()"
              [ngClass]="{
                'added-in-follow-list btn-black-bg': isAddedInFollowList
              }"
              class="btn btn-outline-light btn-blue-bg"
            >
              {{ isAddedInFollowList ? "Unfollow" : "Follow" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showPopUp">
      <div class="compare-popup-background" (click)="showPopUp = false"></div>
      <div class="compare-popup">
        <figure class="img-wrap">
          <img src="assets/images/Pop-up2.svg" class="img" />
        </figure>
        <button
          (click)="openPricePage()"
          class="btn btn-blue-bg btn-outline-light"
        >
          Get access to Pro
        </button>
      </div>
    </div>
  </header>
</div>

<p-sidebar
  [(visible)]="hideNotificationPopup"
  [position]="'right'"
  [blockScroll]="true"
  [dismissible]="true"
  [autoZIndex]="true"
  [showCloseIcon]="false"
>
  <div class="notification-header">
    <h6>Notifications</h6>
    <span
      *ngIf="notificationCount != undefined && notificationCount !== 0"
      class="count-in-header"
      >{{ notificationCount }} New</span
    >
  </div>
  <app-notification-component [header]="true"></app-notification-component>
  <div class="notification-footer">
    <a routerLink="/notification">View All</a>
  </div>
</p-sidebar>

<p-dialog
  [(visible)]="display"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [closable]="true"
  [modal]="true"
  [blockScroll]="true"
  [draggable]="false"
  [dismissableMask]="true"
>
  <ng-template pTemplate="header"> Suggestion Request </ng-template>
  <app-suggestion-form (display)="display = $event"></app-suggestion-form>
</p-dialog>
