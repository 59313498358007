<div class="card-wrap card-with-bg" *ngIf="company?.name">
  <div class="card-top d-flex align-items-center">
    <figure
      class="img-wrap"
      [routerLink]="['/company/', company.companyUrl, 'overview']"
    >
      <!-- [queryParams]="{tab: 'overview'}" -->
      <!-- <img
        *ngIf="
          company.logo !== null &&
          company.logo !== undefined &&
          company.logo !== ''
        "
        src="{{ company?.logo }}"
        class="img"
      /> -->
      <span  class="vname">{{
        company.name | initials
      }}</span>
    </figure>
    <div class="right-wrap">
      <span
        class="company-name"
        [routerLink]="['/company/', company.companyUrl, 'overview']"
        >{{ company.name }}</span
      >
      <!-- [queryParams]="{tab: 'overview'}" -->
      <span class="company-location">{{
        company?.headquaters_location | location
      }}</span>
    </div>
    <div class="wrap-notify" (click)="followCompany()">
      <span class="not-active" *ngIf="!company?.user_follow?.includes(userId)"
        ><img src="assets/images/bell_icon.png" class="image"
      /></span>
      <span class="active-icon" *ngIf="company?.user_follow?.includes(userId)"
        ><img src="assets/images/bell_icon_fill.png" class="image"
      /></span>
    </div>
  </div>
  <div class="summary">
    <span
      [innerHTML]="
        (company.about
          ? company?.about?.substring(0, 120) + '....'
          : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'
        ) | linky
      "
    ></span>
    <!-- {{company.about ? (company?.about?.substring(0, 120)+"...."):'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}} -->
  </div>
  <div class="tag-list d-flex">
    <span
      class="tag-name"
      *ngFor="let industry of company?.industry; let i = index"
    >
      {{ industry | industry
      }}{{ company?.industry.length - 1 == i ? "" : ", " }}
    </span>
    <!-- <span class="tag-name">Technology,</span>
        <span class="tag-name">Financial Service</span> -->
  </div>
  <div class="card-bottom d-flex justify-content-between">
    <!-- <p-confirmPopup></p-confirmPopup> -->
    <!-- <button (click)="followCompany()" [ngClass]="{'added-in-follow-list btn-black-bg': isAddedInFollowList , 'btn-blue-bg' : !isAddedInFollowList}"
    class="btn btn-outline-light">{{ isAddedInFollowList? "Unfollow":"Follow"}}</button> -->
    <button
      class="btn btn-outline-light btn-blue-bg"
      [routerLink]="['/company/', company.companyUrl, 'overview']"
    >
      Open
    </button>
    <!-- [queryParams]="{tab: 'overview'}" -->
    <!-- <button *ngIf="isPremium || isAdvance" (click)="compareCompany()" [ngClass]="{'added-in-compare-list btn-black-bg': isAddedInCompareList}"
      class="btn btn-outline-light btn-grey-bg">{{ isAddedInCompareList? "Added":"Compare"}}</button> -->
    <!-- <button *ngIf="isFree" (click)="showPopUp = !showPopUp" class="btn btn-outline-light btn-grey-bg">Compare</button> -->
  </div>
</div>
<div *ngIf="showPopUp">
  <div class="compare-popup-background" (click)="showPopUp = false"></div>
  <div class="compare-popup">
    <figure class="img-wrap">
      <img src="assets/images/Pop-up2.svg" class="img" />
    </figure>
    <button [routerLink]="'/prices'" class="btn btn-blue-bg btn-outline-light">
      Get access to Pro
    </button>
  </div>
</div>
