import {
  APP_PATHS,
  ADMIN_PATHS,
  LOGIN_PATHS,
  COMMON_CONSTANTS,
  CUSTOMER_PATHS,
} from 'src/app/shared/constants/common-constants';
// import { DeviceDetectorService } from 'ngx-device-detector';
export class CommonComponent {
  APP_PATHS = APP_PATHS;
  CUSTOMER_PATHS = CUSTOMER_PATHS;
  ADMIN_PATHS = ADMIN_PATHS;
  LOGIN_PATHS = LOGIN_PATHS;
  COMMON_CONSTANTS = COMMON_CONSTANTS;
  deviceInfo = null;
}
