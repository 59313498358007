import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LinkyModule } from 'ngx-linky';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonService } from 'src/app/shared/services/angular-service/common.services';
import { RestApiService } from 'src/app/shared/services/angular-service/helper.httpServices';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompareCardComponent } from './modules/customer-module/cards/compare-card/compare-card.component';
import { CustomerComponent } from './modules/customer-module/customer.component';
import { SuggestionFormComponent } from './modules/customer-module/forms/suggestion-popup-card/suggestion-form.component';
import { LoginSignupComponent } from './modules/login-signup-module/login-signup.component';
import { ComapreLayoutComponent } from './shared/components/comapre-layout/comapre-layout.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { USAuthenticationGuard } from './shared/guard/user-auth-guard';
import { TokenInterceptor } from './shared/services/angular-service/interceptor.service';
import { LoaderInterceptorService } from './shared/services/angular-service/loader-interceptor.service';
import { LocalStorageService } from './shared/services/angular-service/local-storage.service';
import { ToastService } from './shared/services/angular-service/toast.service';
import { SharedModule } from './shared/shared.module';
import { SidebarModule } from 'primeng/sidebar';
import { LoginAuthenticationGuard } from './shared/guard/login-auth-guard';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './shared/services/angular-service/url-serializer.service';
import { AngularFireAuthModule } from '@angular/fire/auth';

@NgModule({
  declarations: [
    AppComponent,
    CustomerComponent,
    LoginSignupComponent,
    FooterComponent,
    HeaderComponent,
    ComapreLayoutComponent,
    CompareCardComponent,
    SuggestionFormComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'thekredible' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    InputMaskModule,
    MegaMenuModule,
    MenubarModule,
    LinkyModule,
    DialogModule,
    NgxChartsModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    SharedModule,
    SidebarModule,
    AngularFireAuthModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    ConfirmationService,
    ToastService,
    RestApiService,
    CommonService,
    MessageService,
    LocalStorageService,
    USAuthenticationGuard,
    LoginAuthenticationGuard,
    AsyncPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
