// import { Component, Input,OnChanges } from '@angular/core';
// import { Injectable } from '@angular/core';
// import { MessageService } from 'primeng/api';

// @Injectable()
// export class LoaderService {

//     loaderMessage: string = '';

//     constructor(private messageService: MessageService) { }

//     ngOnChanges() {
//         this.loaderMessage = "Loading";
//     }
// }

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading = new Subject<boolean>();

  constructor() {}
}
