<div class="compare-view-wrap" *ngIf="companylist.length > 0">
  <div class="count-wrap">
    <span class="number">{{ companylist.length }}</span>
    <span class="text">Added</span>
  </div>
  <div class="company-btn-wrap d-flex justify-content-between">
    <ul class="companies-wrap d-flex list-unstyled mb-0">
      <li class="company-type" *ngFor="let company of companylist">
        <app-compare-card [cin]="company"></app-compare-card>
        <span (click)="compareCompany(company)" class="remove"
          ><i class="fas fa-times-circle"></i
        ></span>
      </li>
    </ul>
    <!-- <div [ngClass]="{'compare-btn': companylist.length == 3}" class="btn-wrap ">
      <button *ngIf="companylist.length != 3 && companylist.length < 3 "  (click)="addCompany()" class="btn btn-outline-light  btn-blue-bg margin-right">Add Company</button>
      <button [routerLink]="['/compare']"  class="btn btn-outline-light btn-black-bg margin-right">Compare ({{companylist.length}})</button>
      <button (click)="removeCompany()"   class="btn btn-outline-light btn-grey-bg">Close</button>
    </div> -->
  </div>
</div>
