<section class="section-newswork">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="latest-new">
          <h3 class="h3-heading">Recent News</h3>
          <a href="{{ newsData[0]?.url }}" target="_blank" class="news-title">{{
            newsData[0]?.title
          }}</a>
          <!-- <button class="btn btn-outline-light btn-grey2-bg">Read all News</button> -->
        </div>
        <app-quote-slider></app-quote-slider>
      </div>
      <div class="col-12 col-md-6 col-lg-8">
        <app-google-news-layout
          [home]="true"
          [showNewsImage]="true"
          [count]="6"
          [showExpandMore]="false"
        ></app-google-news-layout>
      </div>
    </div>
  </div>
</section>
