import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';

@Component({
  selector: 'app-compare-card',
  templateUrl: './compare-card.component.html',
  styleUrls: ['./compare-card.component.scss'],
})
export class CompareCardComponent implements OnInit {
  @Input() cin;
  compantData: any;

  constructor(private companyService: CompanyService, private router: Router) {}

  ngOnInit(): void {
    this.companyService
      .getCompanyDetail('?cin=' + this.cin)
      .then((data) => {
        this.compantData = data;
      })
      .catch((err) => {});
  }

  companyPage(name) {
    this.router.navigate(['company', name], {
      queryParams: { tab: 'Overview' },
    });
  }
}
