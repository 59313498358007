import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { SeoService } from './shared/services/angular-service/seo.service';
import { SettingService } from './shared/services/node-api/setting.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Discover Companies Information & Database with TheKredible';
  version = 'Angular version 11.0.7';
  message: any = null;

  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private seoService: SeoService,
    public settingService: SettingService,
  ) // private msg: AngularFireMessaging,

  {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit(): void {
    // this.msg.messages.subscribe(
    //   (payload) => {
    //     console.log("AppComponent new message received :- ", payload);
    //     // this.currentMessage.next(payload);
    //     // this.getNotificationCount();
    //   });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let currentRequest = this.router.url.replace('?', '/');
        let Url = currentRequest.split('/');

        if (Url.length == 2 && Url[0] == '' && Url[1] == '') {
          // for home page only
          this.seoService.getSeo('');
        } else if (Url.includes('companies')) {
          this.seoService.getSeo('companies');
        } else if (Url.includes('prices')) {
          this.seoService.getSeo('prices');
        } else if (Url.includes('blogs')) {
          this.seoService.getSeo('blogs');
        } else if (Url.includes('login')) {
          this.seoService.getSeo('login');
        } else if (Url.includes('about-us')) {
          this.seoService.getSeo('about-us');
        } else if (Url.includes('contact-us')) {
          this.seoService.getSeo('contact-us');
        } else if (Url.includes('career')) {
          this.seoService.getSeo('career');
        } else if (Url.includes('team')) {
          this.seoService.getSeo('team');
        } else if (Url.includes('privacy-policy')) {
          this.seoService.getSeo('privacy-policy');
        } else if (Url.includes('terms-and-conditions')) {
          this.seoService.getSeo('terms-and-conditions');
        } else if (Url.includes('refund-policy')) {
          this.seoService.getSeo('refund-policy');
        }
      }
    });

    this.getAllDropdownList();
  }

  getAllDropdownList() {
    this.settingService.getAllDropdowns().then(
      (data: any) => {
        localStorage.setItem('locations', JSON.stringify(data.data.locations));
        localStorage.setItem('industries', JSON.stringify(data.data.industries));
        localStorage.setItem('financialYears', JSON.stringify(data.data.financialYears));
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
