import { Pipe, PipeTransform } from '@angular/core';
import { GLOBAL_VARIABIALES } from '../constants/static-variable';
import { SettingService } from '../services/node-api/setting.service';

@Pipe({
  name: 'location',
})
export class LocationPipe implements PipeTransform {
  LOCATION = [];

  constructor(private settingService: SettingService,){}

  transform(locationData?: any) {

    this.LOCATION = this.settingService.getLocationDropdown();

    var locationName=''

    if(this.LOCATION !== null){
      this.LOCATION.forEach(element => {
        if(element.key === locationData){
          locationName = element.label;
        }
    });
    }

    return locationName;
  }
}
