import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private subject = new Subject<any>();

  sendSession(session: any) {
    this.subject.next(session);
  }

  clearSessions() {
    this.subject.next();
  }

  onSession(): Observable<any> {
    return this.subject.asObservable();
  }
}
