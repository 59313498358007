import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(name: String) {
    if (name) {
      if (name.split(' ').length > 1) {
        return name
          .split(' ')
          .splice(0, 2)
          .map((word) => word.charAt(0).toUpperCase())
          .join('');
      } else {
        return name.substring(0, 2).toUpperCase();
      }
    } else {
      return name;
    }
  }
}
