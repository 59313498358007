<section class="section-getmore" *ngIf="plan == null || isAdvance || isFree">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="left-content">
          <h2 class="h2-heading">
            Get more with <span class="bold">TheKredible Pro</span>
          </h2>
          <p class="discription">
            TheKredible Subscription plans provide users with authentic and accurate data giving them <br />a portal to a wealth of knowledge about Indian startup companies.
          </p>
          <a href="/prices" *ngIf="plan == null" class="btn btn-blue-bg btn-outline-light"
            >Get Started</a
          >
          <a href="/prices" *ngIf="plan !== null && (isAdvance || isFree)" class="btn btn-blue-bg btn-outline-light"
            >Upgrade Now</a
          >
          <!-- <p class="small-des">Already have an account?</p> -->
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-8">
        <div class="right-content d-flex justify-content-center">
          <ul class="d-flex align-items-center list-unstyled mb-0">
            <li>Company Overview</li>
            <li>Company Financials</li>
            <li>Company Funding</li>
            <p class="more">And lot more...</p>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
