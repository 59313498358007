<app-loader></app-loader>
<p-toast
  [showTransitionOptions]="'300ms'"
  [hideTransitionOptions]="'300ms'"
  position="bottom-left"
></p-toast>
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<app-comapre-layout></app-comapre-layout>
