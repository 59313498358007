export const APP_PATHS = {
  HOME: '',
  USER: 'user',
  ADMIN: 'admin',
  ERROR_404: '404',
  UNIVERSAL_ROUTE: '**',
};

export const CUSTOMER_PATHS = {
  PROFILE: 'profile',
};

export const LOGIN_PATHS = {
  HOME: '',
  LOGIN: 'login',
  SIGNUP: 'signup',
  SIGNUP_OTP: 'signup/otp',
  FORGET_PASSWORD: 'forget-password',
  FORGET_PASSWORD_OTP: 'forget-password-varify-otp',
  FORGETPASSWORD_CHANGE_PASSWORD: 'forget-password/change-password',
  ADMIN_LOGIN: 'admin/login',
};

export const ADMIN_PATHS = {
  HOME: '',
  COMPANY: 'company',
  COMPANY_CREATE: 'company/create',
  COMPANY_EDIT: 'company/edit',
  COMPANY_DELETE: 'company/delete',
  COMPANY_REPORT: 'company/report',
  FIELD: 'field',
  FIELD_CREATE: 'field/create',
  FIELD_EDIT: 'field/edit',
  FIELD_DELETE: 'field/delete',
  NOTIFICATION: 'notification',
  SUBUSER: 'subuser',
  TEMPLATE: 'template',
  TEMPLATE_CREATE: 'template/create',
  TEMPLATE_EDIT: 'template/edit',
  TAB: 'tab',
  TAB_CREATE: 'tab/create',
  TAB_EDIT: 'tab/edit',
  USER: 'user',
  COMPLAINT: 'complaint',
  CREATE_USER: 'user/create',
  EDIT_USER: 'user/edit',
  PAGE: 'page',
  CREATE_PAGE: 'page/create',
  PREVIEW_PAGE: 'page/preview',
};

export const COMMON_CONSTANTS = {
  USER_ROLE: {
    SUPER_ADMIN: 'su_admin',
    ADMIN: 'admin',
    SUBSCRIBER: 'subscriber',
    GUEST: 'guest',
  },
  USER_STATUS: {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
  },
};

export const FORM_TYPES = {
  CREATE_FIELD: 'field',
  CREATE_TAB: 'tab',
  CREATE_TEMPLATE: 'template',
  CREATE_COMPANY: 'company',
  ADMIN_LOGIN: 'a_login',
  USER_LOGIN: 'u_login',
};
