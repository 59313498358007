import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/angular-service/common.services';
import { LocalStorageService } from '../../services/angular-service/local-storage.service';

@Component({
  selector: 'app-comapre-layout',
  templateUrl: './comapre-layout.component.html',
  styleUrls: ['./comapre-layout.component.scss'],
})
export class ComapreLayoutComponent implements OnInit {
  public companylist: any = [];
  companyList: any;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {
    this.companylist = this.commonService.getCompareCompanyList();
    this.companyList = JSON.parse(
      this.localStorage.getItem('compareCompanyList')
        ? this.localStorage.getItem('compareCompanyList')
        : '[]'
    );
    // console.log(this.companylist);
  }

  ngOnInit(): void {
    this.commonService.compareCompanyList.subscribe((data: any) => {
      this.companylist = data;
      // console.log(data);
    });
  }

  addCompany() {
    this.router.navigateByUrl('/search');
  }

  compareCompany(cin) {
    this.commonService.addToCompareList(cin);
    if (this.companylist?.length === 0) {
      this.router.navigate(['/']);
    }
  }

  removeCompany() {
    this.companyList = JSON.parse(
      this.localStorage.getItem('compareCompanyList')
        ? this.localStorage.getItem('compareCompanyList')
        : '[]'
    );
    this.companyList.forEach((element) => {
      this.commonService.addToCompareList(element);
    });
    if (this.companylist?.length === 0) {
      this.router.navigate(['/search']);
    }
  }
}
