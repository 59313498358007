import { Component, OnInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { ConfirmationService, MegaMenuItem } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
import { CommonService } from '../../services/angular-service/common.services';
import { CompanyService } from '../../services/node-api/company.service';
import { LoginSignupService } from '../../services/node-api/login-signup.service';
import { LocalStorageService } from '../../services/angular-service/local-storage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../services/angular-service/toast.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  showFooter;
  isAddedInCompareList: any = false;
  isAddedInFollowList: any = false;
  company: any;
  loginEmail: string;
  isCompanyDetailsHeader = false;
  company_name: any;
  plan: any;
  isPremium = false;
  isAdvance = false;
  isFree = false;
  subscribeForm: FormGroup;
  token: any = undefined;
  company_url: any;
  currentYear: number;


  constructor(
    private router: Router,
    private commonService: CommonService,
    private companyService: CompanyService,
    private loginSignupService: LoginSignupService,
    private localStorageService: LocalStorageService,
    public toastService: ToastService,
    public confirmationService: ConfirmationService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {

    this.currentYear = new Date().getFullYear();

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (
          event.url === '/user/signup' ||
          event.url === '/user/login' ||
          event.url === '/user/forget-password' ||
          event.url === '/user/forget-password-varify-otp' ||
          event.url.includes('/print')
        ) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      });

    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof ActivationEnd &&
            Object.keys(e.snapshot.params).length > 0
        ),
        map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
      )
      .subscribe((data) => {
        // console.log(data)
        if (data.tab) {
          this.company_name = data.name;
          //  decodeURIComponent(this.router.url.substring(
          //   this.router.url.indexOf("/company") + 9,
          //   this.router.url.lastIndexOf("?")
          // ));
          this.company_url = this.company_name.toLowerCase()
          this.company_url = this.company_url.replaceAll(' ', '-');
          let request = '?companyUrl=' + this.company_url;
          this.companyService
            .getCompanyDetail(request)
            .then((data: any) => {
              this.company = data;
              this.isAddedInFollowList = this.commonService.isInFollowlist(
                this.company._id
              );
              this.isAddedInCompareList = this.commonService.isInComparelist(
                this.company.cin
              );
            })
            .catch((err: any) => {
              // console.log(err)
            });
          this.isCompanyDetailsHeader = true;
        }
      });

    // Display header only if its company detail route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          !(
            event.url.split('/').length >= 4 &&
            event.url.split('/')[1] == 'company'
          )
        ) {
          this.isCompanyDetailsHeader = false;
        }
      }
    });

    this.subscribeForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
      ]),
    });
  }

  ngOnInit(): void {
    this.recaptchaV3Service
      .execute('subscription')
      .subscribe((token: string) => {
        this.token = token;
      });

    this.loginEmail = this.loginSignupService.getUserSession()?.user?.name;
    this.isAddedInCompareList = this.commonService.isInComparelist(
      this.company?.cin
    );
    this.plan = this.localStorageService.getItem('plan');
    if (this.plan === 'plan_advance') {
      this.isAdvance = true;
    } else if (this.plan === 'plan_premium') {
      this.isPremium = true;
    } else {
      this.isFree = true;
    }
  }

  subscribe() {
    let subscribeData = this.subscribeForm.value;
    subscribeData['source'] = 'subscribe';
    if (this.subscribeForm.valid) {
      this.loginSignupService
        .createSubscribe(subscribeData, this.token)
        .then((data) => {
          this.subscribeForm.reset();
          this.toastService.addSingle(
            'success',
            'Subscribed Successfully!',
            ''
          );
        })
        .catch((err) => {
          console.log(err);
          this.recaptchaV3Service
            .execute('subscription')
            .subscribe((token: string) => {
              this.token = token;
            });
        });
    }
  }

  compareCompany() {
    this.isAddedInCompareList = this.commonService.addToCompareList(
      this.company.cin
    );
  }

  confirm(event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'This feature is only available for Advance and Premium users.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['/prices']);
      },
      reject: () => {
        return false;
      },
    });
  }

  followCompany() {
    this.loginEmail = this.loginSignupService.getUserSession()?.user?.name;
    if (
      this.loginEmail !== undefined &&
      this.loginEmail !== '' &&
      this.loginEmail !== null
    ) {
      this.isAddedInFollowList = this.commonService.addToFollowList(
        this.company._id
      );
      let request = {
        cin: this.company.cin,
      };
      this.commonService
        .addFollowInCompanyList(request)
        .then((data: any) => {
          this.isAddedInFollowList = this.commonService.isInFollowlist(
            this.company._id
          );
          window.location.reload();
        })
        .catch((err: any) => {
          // console.log(err)
        });
    } else {
      this.router.navigate(['/user/login']);
    }
  }
}
