<div class="right-wrap">
  <div *ngIf="showTittle">
    <h3>{{ title }}</h3>
  </div>
  <div class="wrap-listing">
    <ng-container *ngFor="let news of newsData">
      <app-google-news-card
        [showNewsImage]="showNewsImage"
        [news]="news"
      ></app-google-news-card>
    </ng-container>
  </div>
  <div
    *ngIf="!showExpandMore && totalRecords != newsData.length && !home"
    class="btn-link-wrap text-center"
  >
    <a
      [routerLink]="['/company/' + this.newsData[0]?.company_name, 'news']"
      fragment="{{ categoryName }}"
      class="link-btn"
      >Read all News Articles <i class="pi pi-arrow-right"></i
    ></a>
    <!-- [queryParams]="{tab: 'news'}" -->
  </div>

  <div
    *ngIf="showExpandMore && totalRecords != newsData.length"
    class="btn-link-wrap text-center"
  >
    <a (click)="showMore()" class="link-textmore">Expand more</a>
  </div>
</div>
