import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-news-card',
  templateUrl: './google-news-card.component.html',
  styleUrls: ['./google-news-card.component.scss'],
})
export class GoogleNewsCardComponent implements OnInit {
  @Input() showNewsImage = false;
  @Input() news;

  constructor() {}

  ngOnInit(): void {}
}
