<div class="recent-msg-slide">
  <div class="d-flex justify-content-between">
    <div class="quote-title">
      <img src="assets/images/qoute.svg" class="img" />
      <span class="title">Kredible Facts</span>
    </div>
    <!-- <div class="arrow-wrap">
      <span class="left-icon"><i class="pi pi-angle-left"></i></span>
      <span cass="right-icon"><i class="pi pi-angle-right"></i></span>
    </div> -->
  </div>
  <p-carousel [value]="quotesList" [numVisible]="1" [numScroll]="1">
    <ng-template let-quote pTemplate="item">
      <a class="title-text">{{ quote }}</a>
    </ng-template>
  </p-carousel>
</div>
