<div class="wrap-notification space-mt-mb" *ngIf="!header">
  <div class="col-12 col-md-6 ml-auto mr-auto">
    <div class="notification-header">
      <h6>Notifications</h6>
      <div class="wrap-right">
        <button (click)="markAllRead()">Mark all read</button>
        <div class="unreadbtn">
          <span>Only show unread</span>
          <p-inputSwitch
            [(ngModel)]="checked"
            (onChange)="handleChange($event)"
          ></p-inputSwitch>
        </div>
      </div>
    </div>

    <div
      class="notification-list"
      infiniteScroll
      [infiniteScrollDistance]="8"
      [infiniteScrollThrottle]="500"
      [alwaysCallback]="true"
      (scrolled)="onScroll($event)"
    >
      <div *ngFor="let item of notificationList" class="notfy-item-wrap">
        <div class="item-left">
          <a class="notfy-item" (click)="openPage(item)">
            <span class="company-initials">{{
              item.notification?.companyName | initials
            }}</span>
            <div>
              <strong>{{ item.notification.label }}</strong
              ><span class="timestamp">{{
                item.notification.updatedAt | dateAgo
              }}</span>
              <p class="notfy-text">
                {{ item.notification.short_description }}
                <span
                  class="read-more"
                  *ngIf="!item.opened"
                  (click)="openMoreNotification(item)"
                  tooltipPosition="top"
                  pTooltip="Show more"
                >
                  <i class="pi pi-ellipsis-h"></i
                ></span>
              </p>
              <p
                *ngIf="item.opened"
                class="notfy-text"
                [innerHTML]="item.notification.description"
              ></p>
            </div>
          </a>
        </div>
        <div class="item-right">
          <button
            *ngIf="item.status === 'Unread'"
            (click)="markRead(item)"
            tooltipPosition="top"
            pTooltip="Mark as read"
          >
            Mark Read
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrap-notification space-mt-mb" *ngIf="header">
  <div class="col-12 col-md-6 ml-auto mr-auto">
    <div class="notification-list">
      <div
        *ngFor="let item of notificationList; let i = index"
        class="notfy-item-wrap"
      >
        <div class="item-left">
          <a class="notfy-item" (click)="openPage(item)">
            <span class="company-initials">{{
              item.notification?.companyName | initials
            }}</span>
            <div>
              <strong>{{ item.notification.label }}</strong
              ><span class="timestamp">{{
                item.notification.updatedAt | dateAgo
              }}</span>
              <p class="notfy-text">
                {{ item.notification.short_description }}
                <span
                  class="read-more"
                  *ngIf="!item.opened"
                  (click)="openMoreNotification(item)"
                  tooltipPosition="top"
                  pTooltip="Show more"
                >
                  <i class="pi pi-ellipsis-h"></i
                ></span>
              </p>
              <p
                *ngIf="item.opened"
                class="notfy-text"
                [innerHTML]="item.notification.description"
              ></p>
            </div>
          </a>
        </div>
        <div class="item-right">
          <button
            *ngIf="item.status === 'Unread'"
            (click)="markRead(item)"
            tooltipPosition="top"
            pTooltip="Mark as read"
          >
            Mark Read
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
