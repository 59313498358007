<div class="fintech-compaineslist-wrap wrap-carousel">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="h3-heading">{{ title }}</h3>
  </div>
  <p-carousel
    *ngIf="companyList.length > 0"
    [value]="companyList"
    [numVisible]="3.5"
    [numScroll]="2"
  >
    <ng-template let-company pTemplate="item">
      <app-company-card [company]="company"></app-company-card>
    </ng-template>
  </p-carousel>
</div>
