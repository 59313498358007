import { Injectable } from '@angular/core';
import { RestApiService } from '../angular-service/helper.httpServices';
import { LocalStorageService } from '../angular-service/local-storage.service';
import { GLOBAL } from '../global';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(
    private http: RestApiService,
    private localStorage: LocalStorageService
  ) {}

  // getNews(url) {
  //   return new Promise((resolve, reject) => {
  //     this.http.post(url).subscribe(
  //       (response) => {
  //         resolve(response);
  //       },
  //       (err) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  getNewsDetail(requestData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .get<any>(GLOBAL.url + 'news/customer/find' + requestData)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getNewsList(request: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'news/customer/findAll', request)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getrecentNewsList(request: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(GLOBAL.url + 'news/customer/recent/news/findAll', request)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
