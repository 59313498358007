import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ConfirmationService, MegaMenuItem } from 'primeng/api';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ActivationEnd,
} from '@angular/router';
import { CommonComponent } from 'src/app/CommonComponent';
import { SessionService } from '../../services/angular-service/session.service';
import { fromEvent, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { LoginSignupService } from '../../services/node-api/login-signup.service';
import { CommonService } from '../../services/angular-service/common.services';
import { CompanyService } from '../../services/node-api/company.service';
import { LocalStorageService } from '../../services/angular-service/local-storage.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { TansactionService } from '../../services/node-api/tansaction.service';
import { ToastService } from '../../services/angular-service/toast.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends CommonComponent implements OnInit {
  @ViewChild('searchOption') searchOption: ElementRef;
  @Output() itemAddedToCart: EventEmitter<any> = new EventEmitter();

  notificationCount = 0;
  cartCount = 0;
  items: MegaMenuItem[];
  subscription: Subscription;
  // session: any;
  customerLandingPage;
  isCompanyDetailsHeader = false;
  overview = true;
  funding = false;
  financials = false;
  traction = false;
  people = false;
  news = false;
  roc = false;
  company_name: any;
  isPremium = false;
  plan: any;
  isAdvance = false;
  isFree = false;
  showMenu = false;
  showPopUp = false;
  hide = false;

  searchTextHeader = new Subject<string>();

  notificationCountSubject = new Subject<any>();

  urlPosition: any;
  loginEmail: string;
  user: any;
  isAddedInCompareList: any = false;
  isAddedInFollowList: any = false;
  company: any;
  slug: string = '';
  companyData: any = [];
  hideNotificationPopup = false;
  emailId: String;

  product;
  company_url: any;

  constructor(
    private msg: AngularFireMessaging,
    private router: Router,
    private sessionService: SessionService,
    private loginSignupService: LoginSignupService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private companyService: CompanyService,
    private localStorageService: LocalStorageService,
    public confirmationService: ConfirmationService,
    private tansactionService: TansactionService,
    private toastService: ToastService
  ) {
    super();
    this.commonService.product.subscribe((productData) => {
      this.product = productData;
    });
    this.emailId = this.loginSignupService.getUserSession()?.user?.email;
    this.user = this.loginSignupService.getUserSession();
    this.subscription = this.sessionService.onSession().subscribe((data) => {
      this.user = data;
    });
    if (this.company) {
      this.commonService.compareCompanyList.subscribe((data) => {
        this.isAddedInCompareList = this.commonService.isInComparelist(
          this.company.cin
        );
      });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.hideNotificationPopup = false;
        this.showMenu = false;
        this.urlPosition = this.router.url;
        if (
          event.url === '/user/signup' ||
          event.url === '/user/login' ||
          event.url === '/user/forget-password' ||
          event.url === '/user/forget-password-varify-otp' ||
          event.url.includes('/print')
        ) {
          this.customerLandingPage = false;
          this.companyData = undefined;
        } else {
          // this.session = this.loginSignupService.getUserSession();
          this.customerLandingPage = true;
        }
      });

    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof ActivationEnd &&
            Object.keys(e.snapshot.params).length > 0
        ),
        map((e) => (e instanceof ActivationEnd ? e.snapshot.params : {}))
      )
      .subscribe((data) => {
        // console.log(data)
        if (data.tab) {
          this.companyData = undefined;
          this.company_name = data.name;
          //  decodeURIComponent(this.router.url.substring(
          //   this.router.url.indexOf("/company") + 9,
          //   this.router.url.lastIndexOf("?")
          // ));
          this.company_url = this.company_name.toLowerCase()
          this.company_url = this.company_url.replaceAll(' ', '-');
          let request = '?companyUrl=' + this.company_url;
          this.companyService
            .getCompanyDetail(request)
            .then((data: any) => {
              this.company = data;
              this.isAddedInFollowList = this.commonService.isInFollowlist(
                this.company._id
              );
              this.isAddedInCompareList = this.commonService.isInComparelist(
                this.company.cin
              );
            })
            .catch((err: any) => {
              // console.log(err)
            });

          this.isCompanyDetailsHeader = true;
          this.plan = this.localStorageService.getItem('plan');
          if (this.plan === 'plan_advance') {
            this.isAdvance = true;
          } else if (this.plan === 'plan_premium') {
            this.isPremium = true;
          } else {
            this.isFree = true;
          }
          if (data.tab.includes('overview')) {
            this.overview = true;
            this.funding = false;
            this.financials = false;
            this.traction = false;
            this.people = false;
            this.news = false;
            this.roc = false;
          } else if (data.tab.includes('funding')) {
            this.overview = false;
            this.funding = true;
            this.financials = false;
            this.traction = false;
            this.people = false;
            this.news = false;
            this.roc = false;
          } else if (data.tab.includes('financials')) {
            this.overview = false;
            this.funding = false;
            this.financials = true;
            this.traction = false;
            this.people = false;
            this.news = false;
            this.roc = false;
          } else if (data.tab.includes('traction')) {
            this.overview = false;
            this.funding = false;
            this.financials = false;
            this.traction = true;
            this.people = false;
            this.news = false;
            this.roc = false;
            this.commonService.putProductData(null);
          } else if (data.tab.includes('people')) {
            this.overview = false;
            this.funding = false;
            this.financials = false;
            this.traction = false;
            this.people = true;
            this.news = false;
            this.roc = false;
          } else if (data.tab.includes('news')) {
            this.overview = false;
            this.funding = false;
            this.financials = false;
            this.traction = false;
            this.people = false;
            this.news = true;
            this.roc = false;
            this.commonService.putProductData(null);
          } else if (data.tab.includes('roc')) {
            this.overview = false;
            this.funding = false;
            this.financials = false;
            this.traction = false;
            this.people = false;
            this.news = false;
            this.roc = true;
            this.commonService.putProductData(null);
          }
        } else {
          this.commonService.putProductData(null);
          this.isCompanyDetailsHeader = false;
        }
      });

    // Display header only if its company detail route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.cartCount = 0;
        this.getNotificationCount();
        if (
          !(
            event.url.split('/').length >= 4 &&
            event.url.split('/')[1] == 'company'
          )
        ) {
          this.isCompanyDetailsHeader = false;
          this.companyData = undefined;
        }
      }
    });
  }

  logOut() {
    this.loginSignupService.userLogout();
  }

  ngOnInit() {
    this.cartCount = 0;
    this.commonService.notificationCountSubject.subscribe((data) => {
      this.getNotificationCount();
    });

    this.msg.requestToken.subscribe(
      (token) => {
        // console.log("FCM Token :-", token);
        this.loginSignupService.setFCMToken(token);
      },
      (error) => {
        console.log(error);
      }
    );

    this.msg.onMessage((payload) => {
      console.log('HeaderComponent new message received :- ', payload);
      this.getNotificationCount();
    });

    // this.msg.onBackgroundMessage.subscribe(
    //   (payload) => {
    //     console.log("HeaderComponent new message received :- ", payload);
    //     // this.currentMessage.next(payload);
    //     this.getNotificationCount();
    //   });

    this.loginEmail = this.loginSignupService.getUserSession()?.user?.name;
    this.isAddedInCompareList = this.commonService.isInComparelist(
      this.company?.cin
    );
    this.plan = this.localStorageService.getItem('plan');
    if (this.plan === 'plan_advance') {
      this.isAdvance = true;
    } else if (this.plan === 'plan_premium') {
      this.isPremium = true;
    } else {
      this.isFree = true;
    }
    this.getNotificationCount();
  }

  logout() {
    this.loginSignupService.userLogout();
    // this.session = undefined;
    this.router.navigate(['/']);
  }

  route(param: any) {
    switch (param) {
      case 'Admin':
        this.router.navigate(['']);
        // code block
        break;
      default:
      // code block
    }
  }

  selectTab(tab) {
    this.router.navigate(['/company/' + this.company_name + '/' + tab]);
    // , { queryParams: { tab: tab } }
  }

  searchQuery = '';

  search(event) {
    if (event.keyCode == 13 || event.keyCode == 9) {
      this.router.navigate(['/search'], {
        queryParams: { s: event.target.value },
      });
      setTimeout(() => {
        this.commonService.search(event);
        this.companyData = undefined;
      }, 1000);
    } else if (this.searchOption.nativeElement.value.length > 0) {
      fromEvent(this.searchOption.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(150),
          distinctUntilChanged(),
          tap((event: KeyboardEvent) => {
            if (this.searchOption.nativeElement.value != this.searchQuery) {
              this.searchQuery = this.searchOption.nativeElement.value;
              let requestData = {
                textSearch: this.searchOption.nativeElement.value,
              };
              this.commonService
                .searchOptionList(requestData)
                .then((data: any) => {
                  if (data.data.length > 0) {
                    this.companyData = data.data;
                  } else {
                    this.companyData = undefined;
                  }
                  // console.log(this.companyData)
                })
                .catch((err: any) => {
                  // console.log(err)
                  this.companyData = undefined;
                });
            }
          })
        )
        .subscribe();
    } else {
      this.companyData = undefined;
    }
  }

  searchDataValue(name) {
    this.companyData = [];
    this.searchOption.nativeElement.value = name;
    this.router.navigate(['/search'], { queryParams: { s: name } });
  }
  compareCompany() {
    this.isAddedInCompareList = this.commonService.addToCompareList(
      this.company.cin
    );
  }

  confirm(event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'This feature is only available for Advance and Premium users.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.router.navigate(['/prices']);
      },
      reject: () => {
        return false;
      },
    });
  }

  followCompany() {
    this.loginEmail = this.loginSignupService.getUserSession()?.user?.name;
    if (
      this.loginEmail !== undefined &&
      this.loginEmail !== '' &&
      this.loginEmail !== null
    ) {
      this.isAddedInFollowList = this.commonService.addToFollowList(
        this.company._id
      );
      let request = {
        cin: this.company.cin,
      };
      this.commonService
        .addFollowInCompanyList(request)
        .then((data: any) => {
          this.isAddedInFollowList = this.commonService.isInFollowlist(
            this.company._id
          );
          // window.location.reload();
        })
        .catch((err: any) => {
          // console.log(err)
        });
    } else {
      this.router.navigate(['/user/login']);
    }
  }

  openPricePage() {
    this.showPopUp = false;
    this.router.navigate(['/prices']);
  }

  display = false;
  showDialog() {
    this.display = true;
  }

  homePage() {
    this.searchOption.nativeElement.value = '';
    this.router.navigate(['/']);
  }

  getNotificationCount() {
    this.commonService
      .getNotificationCount()
      .then((data: any) => {
        this.notificationCount = data.totalRecords;
        if(data.totalCartRecords.length > 0){
          this.cartCount = data.totalCartRecords[0].microProduct
          ? data.totalCartRecords[0].items.length
          : 1;
        }
      })
      .catch((err) => {
        this.notificationCount = 0;
      });
  }
}
