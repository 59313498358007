import { Pipe, PipeTransform } from '@angular/core';
import { GLOBAL_VARIABIALES } from '../constants/static-variable';
import { SettingService } from '../services/node-api/setting.service';

@Pipe({
  name: 'industry',
})
export class IndustryPipe implements PipeTransform {
  private INDUSTRY = [];

  constructor(private settingService: SettingService){}

  transform(industryData?: any) {
    this.INDUSTRY = this.settingService.getIndustryDropdown();

    var industryName=''

    if(this.INDUSTRY !== null){
      this.INDUSTRY.forEach(element => {
        if(element.key === industryData){
          industryName = element.label;
        }
     });
    }

    return industryName;
  }
}
