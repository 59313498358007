import { Inject, Injectable } from '@angular/core';
import { RestApiService } from '../angular-service/helper.httpServices';
import { LocalStorageService } from '../angular-service/local-storage.service';
import { GLOBAL } from '../global';
import { SessionService } from '../angular-service/session.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class LoginSignupService {

  // baseUrl;
  appVerifier;

  constructor(
    private http: RestApiService,
    private localStorage: LocalStorageService,
    private sessionService: SessionService,
    private msg: AngularFireMessaging,
    private afAuth: AngularFireAuth
  ) { }

  setFCMToken(token) {
    this.localStorage.setItem('FCMToken', token);
  }

  userLogoutAPI() {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          GLOBAL.authUrl + 'auth/customer/logout'
        )
        .subscribe(
          (data) => {
            this.sessionService.sendSession(null);
            this.localStorage.clear();
            this.msg.requestToken.subscribe(
              (token) => {
                this.setFCMToken(token);
              },
              (error) => {
                console.log(error);
              }
            );
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  userLogout() {
    this.sessionService.sendSession(null);
    this.localStorage.clear();
    this.msg.requestToken.subscribe(
      (token) => {
        this.setFCMToken(token);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // loginUser(requestData: any, reCaptchaToken: string) {
  //   // const deviceInfo = this.deviceService.getDeviceInfo();
  //   requestData['FCMToken'] = this.localStorage.getItem('FCMToken');
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post<any>(
  //         GLOBAL.authUrl + 'auth/customer/login',
  //         requestData,
  //         reCaptchaToken
  //       )
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  createSubscribe(requestData: any, reCaptchaToken: string) {
    // requestData['FCMToken'] = this.localStorage.getItem('FCMToken');
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'subscribe/customer/create',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setUserData(data: any) {
    this.localStorage.setItem('role', data.role);
    this.localStorage.setItem('email', data.email);
    this.localStorage.setItem('userId', data._id);
    this.localStorage.setItem('name', data.name);
    this.localStorage.setItem('mobile', data.mobile);
    this.localStorage.setItem('followCompanyList', JSON.stringify(data.company_follow_list));
    this.localStorage.setItem('onSession', 'true');
    this.localStorage.setItem('isNCR', data.isNCR);
    this.localStorage.setItem('completeProfile', data.completeProfile);

    if (data.token) {
      this.localStorage.setItem('token', data.token);
    }
    if (data.planSubscribed) {
      this.localStorage.setItem('plan', data.planSubscribed);
    } else {
      this.localStorage.setItem('plan', 'free');
    }
  }

  setTransactionData(data) {
    // this.localStorage.setItem('transactionList', JSON.stringify(data.transactionData));
  }

  getUserSession() {
    if (this.localStorage.getItem('onSession') == 'true') {
      return {
        user: {
          role: this.localStorage.getItem('role'),
          email: this.localStorage.getItem('email'),
          userId: this.localStorage.getItem('userId'),
          mobile: this.localStorage.getItem('mobile'),
          token: this.localStorage.getItem('token'),
          name: this.localStorage.getItem('name'),
          onSession: this.localStorage.getItem('onSession'),
          plan: this.localStorage.getItem('plan'),
          IsNCR: this.localStorage.getItem('isNCR'),

        },
      };
    } else {
      return null;
    }
  }

  gettoken() {
    if (this.localStorage.getItem('token') !== undefined) {
      return this.localStorage.getItem('token');
    } else {
      return null;
    }
  }

  sendOTP(requestData: any, reCaptchaToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'auth/customer/send/OTP',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  verifyOTP(requestData: any, reCaptchaToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'auth/customer/verify/OTP',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // changePassword(requestData: any, reCaptchaToken: string) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .post<any>(
  //         GLOBAL.authUrl + 'auth/customer/password/change',
  //         requestData,
  //         reCaptchaToken
  //       )
  //       .subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (err) => {
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  resetPassword(requestData: any, reCaptchaToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'auth/customer/password/reset',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  resgisterUser(requestData: any, reCaptchaToken: string) {
    requestData['FCMToken'] = this.localStorage.getItem('FCMToken');
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'auth/customer/createUser',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  verifyUser(requestData: any, reCaptchaToken: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'auth/customer/validate',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getUserData(id) {
    return new Promise((resolve, reject) => {
      this.http.get(GLOBAL.authUrl + 'auth/admin/find/' + id).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  signInWithEmailAndPassword(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }

  createUserWithEmailAndPassword(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  facebookAuth() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  twitterAuth() {
    return this.AuthLogin(new firebase.auth.TwitterAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        console.log('You have been successfully logged in!', result);
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  phoneConfirmationResult: any
  phoneAuth(phoneNumber, token) {
    // Ensure the container is empty and ready
    if (this.appVerifier) {
      this.appVerifier.clear();
      this.appVerifier = null;
      // Create a new div element
      // Check if an element with this ID already exists and remove it
      const existingContainer = document.getElementById(
        'recaptcha-container'
      );
      if (existingContainer) {
        existingContainer.remove();
      }
      const recaptchaContainer = document.createElement('div');
      recaptchaContainer.id = 'recaptcha-container';

      // Append this div to a specific element in your DOM, for example, the body
      document.body.appendChild(recaptchaContainer);
    } else {
      // Create a new div element
      const recaptchaContainer = document.createElement('div');
      recaptchaContainer.id = 'recaptcha-container';
      // Append this div to a specific element in your DOM, for example, the body
      document.body.appendChild(recaptchaContainer);
    }
    this.appVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", { size: "invisible" });
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithPhoneNumber(phoneNumber, this.appVerifier)
        .then((confirmationResult) => {
          this.phoneConfirmationResult = confirmationResult
          resolve(confirmationResult)
        })
        .catch((error) => {
          console.log("error with phoneAuth ", error);
          reject(error)
        });
    })

  }

  submitOTP(otp) {
    return this.phoneConfirmationResult.confirm(otp);
  }

  resendOtp(phoneNumber) {
    // Ensure the container is empty and ready
    if (this.appVerifier) {
      this.appVerifier.clear();
      this.appVerifier = null;
      // Create a new div element
      // Check if an element with this ID already exists and remove it
      const existingContainer = document.getElementById('recaptcha-container');
      if (existingContainer) {
        existingContainer.remove();
      }
      const recaptchaContainer = document.createElement('div');
      recaptchaContainer.id = 'recaptcha-container';

      // Append this div to a specific element in your DOM, for example, the body
      document.body.appendChild(recaptchaContainer);
    }
    this.appVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", { size: "invisible" });
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithPhoneNumber(phoneNumber, this.appVerifier)
        .then((confirmationResult) => {
          // this.phoneConfirmationResult = confirmationResult
          resolve(confirmationResult)
        })
        .catch((error) => {
          console.log("error with phoneAuth ", error);
          reject(error)
        });
    })
  }

  changePasswordFirebase(password) {
    const user = firebase.auth().currentUser;

    return new Promise((resolve, reject) => {
      if (user) {
        return user.updatePassword(password).then((success) => {
          resolve(success)
        }).catch((error) => {
          console.log(error);
          reject(error)
        });
      }
      resolve(null)
    })

  }

  sendPasswordResetEmail(email) {
    return this.afAuth.sendPasswordResetEmail(email,
      { url: GLOBAL.baseURI + 'user/login' })
  }

  socialLogout() {
    this.afAuth.signOut()
      .then((data) => {
        console.log("Logout successful", data)
        // Logout successful
      })
      .catch((error) => {
        console.log("Logout error", error)
        // An error occurred
      });
  }

  socialCustomerLogin(requestData: any, reCaptchaToken: string) {
    requestData['FCMToken'] = this.localStorage.getItem('FCMToken');
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          GLOBAL.authUrl + 'auth/customer/login/social',
          requestData,
          reCaptchaToken
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }


  get isAuthenticated(): boolean {
    return this.afAuth.currentUser !== null;
  }

}
