export const environment = {
  production: true,
  reCAPTCHA: {
    siteKey: '6LeSMc8gAAAAADJa5Ib4ClgukZzNW5_hneguDJxs',
  },
  baseURI: 'https://thekredible.com/',
  baseUrl: 'https://api.thekredible.com/',
  url: 'https://api.thekredible.com/v1/',
  authUrl: 'https://api_auth_prod.thekredible.com/v2/',
  cartUrl: 'https://api_cart_prod.thekredible.com/v2/',
  paymentUrl: 'https://api_payment_prod.thekredible.com/v2/',
  wordPressApiUrl: 'https://thekredible.com/blogs',
  wordPressApiEndPoint: '/wp-json/wp/v2/',
  firebaseConfig: {
    apiKey: 'AIzaSyCJ9yecDaOOrhMq6qpHppFlftkdqZ6m5k0',
    authDomain: 'thekredible-b4227.firebaseapp.com',
    projectId: 'thekredible-b4227',
    storageBucket: 'thekredible-b4227.appspot.com',
    messagingSenderId: '786302990941',
    appId: '1:786302990941:web:d92679d42b6f7f3243ebb4',
    measurementId: 'G-PM1RHQPG3X',
  },
  CURRENT_PLAN: {
    ADVANCE: 'plan_advance',
    PREMIUM: 'plan_premium',
  },
  PLAN: {
    ADVANCE: ['plan_advance'],
    PREMIUM: ['plan_premium'],
  },
  RAZOR_PAY_KEY: {
    KEY_ID: 'rzp_live_6FDYdnb9raTxhS',
    KEY_SECRET: '6ZhCK4LwcT0cFduRS6taI29d',
  },
  S3URL: 'https://thekredible-prod.s3.ap-south-1.amazonaws.com/',
  appVersion: '500',
};
