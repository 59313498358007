import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/node-api/company.service';
import { NewsService } from 'src/app/shared/services/wordpress-api/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  private request: any = {
    field: '_id',
    order: -1,
    page: 0,
    rows: 10,
    filter: {
      category: '',
    },
  };
  newsData: any = [];

  constructor(
    public companyService: CompanyService,
    private newsService: NewsService
  ) {}

  ngOnInit(): void {
    this.getNewsDetails();
  }

  getNewsDetails() {
    // this.request.filter.category =  'general';
    this.request.rows = 1;

    this.newsService
      .getNewsList(this.request)
      .then((data: any) => {
        this.newsData = data.data;
      })
      .catch((err: any) => {
        // console.log(err)
      });
  }
}
